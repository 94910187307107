import React, { useContext, useEffect, useState } from 'react';
// import PageFlip from 'react-pageflip';
import { 
    // getDocument,
     GlobalWorkerOptions } from 'pdfjs-dist/webpack';
// import pdfFile from '../../assets/img/DSA_Edition.pdf';
import './FlipBook.css';
import HTMLFlipBook from 'react-pageflip';
// import image1 from '../../assets/img/dsa-additions/DSA_Edition-01.png'
// import image2 from '../../assets/img/dsa-additions/DSA_Edition-02.png'
// import image3 from '../../assets/img/dsa-additions/DSA_Edition-03.png'
// import image4 from '../../assets/img/dsa-additions/DSA_Edition-04.png'
// import image5 from '../../assets/img/dsa-additions/DSA_Edition-05.png'
// import image6 from '../../assets/img/dsa-additions/DSA_Edition-06.png'
// import image7 from '../../assets/img/dsa-additions/DSA_Edition-07.png'
import PostApiCall from '../../Helpers/Api/PostApi';
import { useNavigate } from 'react-router-dom';
import { Col, Modal, Row } from 'react-bootstrap';
import { store } from '../../Helpers/Store/Store';

 

GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js`;

const Flipbook = () => {
    let navigate = useNavigate()
    const [pages, setPages] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const {setShow} = useContext(store)
    const handlePageFlip = (e) => {
        setCurrentPage(e.data);
        if (!isSubscribed && e.data === 5) {
            setShowModal(true);
        }
    };
    // const [pageAspectRatio, setPageAspectRatio] = useState(1); // Default aspect ratio of 1:1 for safety
    // const pageFlipRef = useRef(null);
    // const [currentPage, setCurrentPage] = useState(0);

    // const loadPDF = async () => {
    //     const loadingTask = getDocument(pdfFile);
    //     const pdf = await loadingTask.promise;
    //     const numPages = pdf.numPages;

    //     const imgPromises = [];
    //     for (let i = 1; i <= numPages; i++) {
    //         imgPromises.push(renderPageToImage(pdf, i));
    //     }
    //     const imgUrls = await Promise.all(imgPromises);

    //     if (imgUrls.length % 2 !== 0) {
    //         imgUrls.push(null); // Adds a blank page if pages are odd
    //     }
    //     setPages(imgUrls);
    // };

    // const renderPageToImage = async (pdf, pageNum) => {
    //     const page = await pdf.getPage(pageNum);
    //     const viewport = page.getViewport({ scale: 1 }); // Get the actual aspect ratio

    //     if (pageNum === 1) {
    //         setPageAspectRatio(viewport.height / viewport.width); // Set the aspect ratio based on the first page
    //     }

    //     const canvas = document.createElement('canvas');
    //     const context = canvas.getContext('2d');
    //     canvas.height = viewport.height;
    //     canvas.width = viewport.width;

    //     const renderContext = {
    //         canvasContext: context,
    //         viewport: viewport,
    //     };

    //     await page.render(renderContext).promise;
    //     return canvas.toDataURL();
    // };

    // const handleNext = () => {
    //     if (pageFlipRef.current) {
    //         pageFlipRef.current.pageFlip().flipNext();
    //     }
    // };

    // const handlePrev = () => {
    //     if (pageFlipRef.current) {
    //         pageFlipRef.current.pageFlip().flipPrev();
    //     }
    // };









    // // this code is generate through chatgpt

    // const renderPageToImage = async (pdf, pageNum) => {
    //     const page = await pdf.getPage(pageNum);
    //     const viewport = page.getViewport({ scale: 1.5 }); // Higher scale for better resolution
    
    //     const canvas = document.createElement('canvas');
    //     const context = canvas.getContext('2d');
    //     canvas.width = viewport.width;
    //     canvas.height = viewport.height;
    
    //     const renderContext = {
    //         canvasContext: context,
    //         viewport,
    //     };
    
    //     await page.render(renderContext).promise;
    
    //     return {
    //         src: canvas.toDataURL(),
    //         aspectRatio: viewport.height / viewport.width,
    //     };
    // };
    
    // const loadPDF = async () => {
    //     const loadingTask = getDocument(pdfFile);
    //     const pdf = await loadingTask.promise;
    
    //     const numPages = pdf.numPages;
    //     const pagesWithAspect = [];
    //     for (let i = 1; i <= numPages; i++) {
    //         const page = await renderPageToImage(pdf, i);
    //         pagesWithAspect.push(page);
    //     }
    
    //     // Ensure even number of pages
    //     if (pagesWithAspect.length % 2 !== 0) {
    //         const blankPage = {
    //             src: null,
    //             aspectRatio: pagesWithAspect[0]?.aspectRatio || 1,
    //         };
    //         pagesWithAspect.push(blankPage);
    //     }
    
    //     setPages(pagesWithAspect);
    // };
    
    // const handleNext = () => {
    //     if (pageFlipRef.current && currentPage < pages.length / 2 - 1) {
    //         pageFlipRef.current.pageFlip().flipNext();
    //         setCurrentPage((prev) => prev + 1);
    //     }
    // };
    
    // const handlePrev = () => {
    //     if (pageFlipRef.current && currentPage > 0) {
    //         pageFlipRef.current.pageFlip().flipPrev();
    //         setCurrentPage((prev) => prev - 1);
    //     }
    // };
    

    // end here code chat gpt


 



    // const onFlip = (e) => {
    //     const newPage = e.data;
    //     if (newPage !== currentPage) {
    //         setCurrentPage(newPage);
    //     }
    // };

    // useEffect(() => {
    //     loadPDF();
    // }, []);
    useEffect(()=>{
        window.scrollTo({ top: 0, behavior: 'smooth' });
        GetMagazineEditionImages();
    },[])
    const GetMagazineEditionImages = () => {
        PostApiCall.postRequest({ whereClause: "" }, "GetMagazineEditionImages").then((results) => {
            results.json().then((obj) => {
                if (results.status === 200 || results.status === 201) {
                    setPages(obj.data);
                } else {
                    // notification.error({
                    //   message: `Notification error`,
                    //   description: obj.data,
                    // });
                }
            })
        })
    }

    // useEffect(() => {
    //     const disableRightClick = (e) => {
    //       e.preventDefault();
    //     };
    //     document.addEventListener("contextmenu", disableRightClick);
    //     return () => {
    //       document.removeEventListener("contextmenu", disableRightClick);
    //    };
    //   }, []);
    //   useEffect(() => {
    //     const disableDevToolsShortcut = (e) => {
    //       if (
    //         e.key === "F12" || e.key === "F10" || e.key === "Fn" ||
    //         (e.ctrlKey && e.shiftKey && (e.key === "I" || e.key === "J" || e.key === "C")) ||
    //         (e.metaKey && e.altKey && e.key === "I") // For MacOS
    //       ) {
    //         e.preventDefault();
    //       }
    //     };
    //     window.addEventListener("keydown", disableDevToolsShortcut);
    //     return () => {
    //       window.removeEventListener("keydown", disableDevToolsShortcut);
    //   };
    //   }, []);
    //   useEffect(() => {
    //     const detectDevTools = () => {
    //       if (window.outerWidth - window.innerWidth > 100) {
    //         navigate("/editions")
    //       }
    //     };
    //     window.addEventListener("resize", detectDevTools);
    //     return () => {
    //       window.removeEventListener("resize", detectDevTools);
    //    };
    //    // eslint-disable-next-line
    //   }, []);
    //   useEffect(() => {
    //     const handleKeyDown = (e) => {
    //       if (e.key === "PrintScreen") {
    //         alert("Screenshots are disabled.");
    //         e.preventDefault();
    //       }
    //     };
    
    //     document.addEventListener("keydown", handleKeyDown);
    
    //     return () => {
    //       document.removeEventListener("keydown", handleKeyDown);
    //     };
    //   }, []);
    //   useEffect(() => {
    //     const handleVisibilityChange = () => {
    //       if (document.visibilityState === "hidden") {
    //         navigate("/editions")
    //       }
    //     };
    //     document.addEventListener("visibilitychange", handleVisibilityChange);
    //     return () => {
    //       document.removeEventListener("visibilitychange", handleVisibilityChange);
    //    };
    //    // eslint-disable-next-line
    //   }, []);
    //   useEffect(() => {
    //     const disableKeys = (e) => {
    //       e.preventDefault();
    //     };
    
    //     // Attach event listeners for keydown and keypress
    //     window.addEventListener("keydown", disableKeys);
    //     window.addEventListener("keypress", disableKeys);
    
    //     // Cleanup event listeners on component unmount
    //     return () => {
    //       window.removeEventListener("keydown", disableKeys);
    //       window.removeEventListener("keypress", disableKeys);
    //     };
    //   }, []);
    //   const handleContextMenu = (e) => {
    //     e.preventDefault();
    //     alert("Right-click is disabled on this page.");
    //   };
    // const flipbookHeight = Math.min(window.innerWidth * pageAspectRatio, window.innerHeight * 0.9); // Limit height to 90% of viewport
    // const pages1 = [{imrurl:image1},
    //     {imrurl:image2},
    //     {imrurl:image3},
    //     {imrurl:image4},
    //     {imrurl:image5},
    //     {imrurl:image6},
    //     {imrurl:image7},
    // ]
    return (
        <section className="section-spacing flip-book">
            {/* <div onContextMenu={handleContextMenu}> */}
            <div className="container flip-book-wrapper ">
                <div className="row">
                    <div className="col-12">
                        <div className="full-screen-container" >
                            <HTMLFlipBook
                            width={350}
                            height={500}
                            size="stretch"
                            onFlip={handlePageFlip}
                            // style={{ pointerEvents: showModal ? "none" : "auto" }}
                            // maxShadowOpacity={0.5}
                            showCover={true}
                            // mobileScrollSupport={true}
                            // className="demo-book"
                            >
                                
                                {pages?.map((dat)=>{
                                    return(
                                        <div>
                                            <img style={{width:"100%"}} src={dat.fld_image} alt=''/>
                                        </div>
                                    )
                                })}
                            </HTMLFlipBook>
                            {/* <PageFlip
                                ref={pageFlipRef}
                                width={window.innerWidth}
                                height={window.innerHeight}
                                size="fixed"
                                style={{ cursor: "pointer" }}
                                onFlip={onFlip}
                            >
                                {pages.reduce((pairs, page, index) => {
                                    if (index % 2 === 0) {  
                                        const pairedPages = (
                                            <div className="page-pair" key={index}>
                                                <div className="left-page">
                                                    {page ? <img src={page} alt={`Page ${index + 1}`} /> : <div className="page-placeholder" />}
                                                </div>
                                                <div className="right-page">
                                                    {pages[index + 1] ? <img src={pages[index + 1]} alt={`Page ${index + 2}`} /> : <div className="page-placeholder" />}
                                                </div>
                                            </div>
                                        );
                                        pairs.push(pairedPages);
                                    }
                                    return pairs;
                                }, [])}
                            </PageFlip> */}

                            {/* <div className="pagination align-items-center">
                                <button className="theme-btn theme-btn-bg" onClick={handlePrev} disabled={currentPage === 0}>
                                    Previous
                                </button>
                                <span className="current-page">
                                    Page {currentPage + 1} of {pages.length}
                                </span>
                                <button className="theme-btn theme-btn-bg" onClick={handleNext} disabled={currentPage === pages.length - 1}>
                                    Next
                                </button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <Modal 
                show={showModal}
                backdrop="static" 
                centered
                size='lg'
                onHide={()=>setShowModal(false)}>
                    <Modal.Header></Modal.Header>
            <Modal.Body>
                                <h4>Opps! you Don't have an active plan</h4>
                                <h5>Please Login or subscribe to view more pages</h5>
                    <Row>
                        <Col lg={6}>
                        <button type="submit" onClick={()=>setShow(true)} className="login-btn w-100">
                            Login
                        </button>
                        </Col>
                        <Col lg={6}>
                        <button type="submit" onClick={()=>navigate("/services")} className="login-btn w-100">
                            Subscribe
                        </button>
                        </Col>
                    </Row>
            </Modal.Body>
            </Modal>
            {/* </div> */}
        </section>
    );
};

export default Flipbook;
