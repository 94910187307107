import { Swiper, SwiperSlide } from 'swiper/react';
import northAmerica from "../../../../assets/img/News/Asset 6@300x.png";
import southAmerica from "../../../../assets/img/News/Asset 7@300x.png";
import asia from "../../../../assets/img/News/Asset 3@300x.png";
import europe from "../../../../assets/img/News/Asset 5@300x.png";
import australia from "../../../../assets/img/News/Asset 4@300x.png";
import antratica from "../../../../assets/img/News/Asset 2@300x.png";
import africa from "../../../../assets/img/News/Asset 1@300x 1.png";

 


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required modules
import { FreeMode, Navigation, Pagination } from 'swiper/modules';
import "./RegionSection.css";
import { useContext } from 'react';
import { store } from '../../../../Helpers/Store/Store';
export default function RegionSection() {
    const {setRegion} = useContext(store)
    return (
        <section className="section-spacing px-0 pt-0 region-section" id="regionsec">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="main-heading text-center">
                            <h2>
                                region
                            </h2>
                        </div>
                        <Swiper
                            slidesPerView={1.5}
                            spaceBetween={20}
                            freeMode={true}
                            navigation
                            pagination={false}
                            breakpoints={{
                                640: {
                                    slidesPerView: 3,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 4,
                                    spaceBetween: 20,
                                },
                                1024: {
                                    slidesPerView: 5,
                                    spaceBetween: 30,
                                },
                            }}
                            modules={[FreeMode, Pagination,Navigation]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <img src={northAmerica} onClick={()=>{setRegion("North America")}} className="img-fluid cursor-pointer" alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={southAmerica} onClick={()=>{setRegion("South America")}} className="img-fluid cursor-pointer" alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={asia} onClick={()=>{setRegion("Asia")}} className="img-fluid cursor-pointer" alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={europe} onClick={()=>{setRegion("Europe")}} className="img-fluid cursor-pointer" alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={africa} onClick={()=>{setRegion("Africa")}} className="img-fluid cursor-pointer" alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={australia} onClick={()=>{setRegion("Australia")}} className="img-fluid cursor-pointer" alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={antratica} onClick={()=>{setRegion("Antarctica")}} className="img-fluid cursor-pointer" alt="" />
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    )
}