import React, { useContext } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


// import required modules
import { Autoplay,Pagination} from 'swiper/modules';

import "./HomeBanner.css";
import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import BgShape from "../../../assets/img/bg-shapes/1.png";
import Army from "../../../assets/img/home/home-banner/army.png";
import Navy from "../../../assets/img/home/home-banner/navy.png";
import AirForce from "../../../assets/img/home/home-banner/air-force.png";
import { store } from '../../../Helpers/Store/Store';
export default function HomeBanner({banners}) {
    const {setScrollTodiv} = useContext(store)
    return (
        <section className="section-spacing home-banner">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-2  col-md-2  d-flex flex-lg-column  flex-md-column order-2 py-3 order-lg-1  order-md-1 justify-content-between">
                        <div className="count-card">
                            <p className="count">
                                50+
                            </p>
                            <span className="content">
                            Areas Covered
                            </span>
                        </div>
                        <div className="count-card">
                            <p className="count">
                                1K+
                            </p>
                            <span className="content">
                            Contributors
                            </span>
                        </div>
                        <div className="count-card pb-2">
                            <p className="count">
                                180+
                            </p>
                            <span className="content">
                            Editions 
                            </span>
                        </div>
                    </div>
                    <div className="col-lg-10 col-md-10 order-1 order-lg-2 order-md-2 ">
                        <div className="banner-section">
                            <Swiper pagination={{
                                clickable: true,
                                
                            }}

                            autoplay={true}
                            slidesPerView={1}
                            // autoplay={true}¿
                            loop={true}
                            // navigation={true}
                            modules={[Pagination,Autoplay]} 
                            // Autoplay={true}
                            className="mySwiper">

                            {
                                banners.map((item,id)=> {return (<SwiperSlide>
                                    <img src={item?.fld_desktop_image} className='img-fluid' alt={item?.fld_alt} />
                                </SwiperSlide>)})
                            }
                             
                            </Swiper>
                            <div className="banner-btn">
                                <div className='position-relative'>
                                    <img src={BgShape} className='img-fluid' alt="" />
                                    <Link to="/services" onClick={()=>{setScrollTodiv("associate")}}>Associate with <i>DSA</i> <FaArrowRight /></Link>
                                </div>
                            </div>
                            <div className="bottom-three-circle">
                              <Link target='_blank' to="https://indianarmy.nic.in/"> <p><img src={Army} className='img-fluid' alt="" /></p></Link> 
                               <Link target='_blank' to="https://www.joinindiannavy.gov.in/"> <p><img src={Navy} className='img-fluid' alt="" /></p></Link>
                               <Link target='_blank' to="https://indianairforce.nic.in/"><p><img src={AirForce} className='img-fluid' alt="" /></p></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}   