export default function MagazinePageContentSection() {
    return (
        <section className="section-spacing mt-4 pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <p className="fs-2 fw-bolder mb-4 lh-base">
                        Welcome to Defence and Security Alert <b> <i> DSA </i></b>  Magazine!
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}