import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
// import Asociate from "../../../assets/img/icons/Associate with DSA.png";
// import subscribe from "../../../assets/img/icons/Subscribe.png";
import Associate from  "../../../assets/img/home/home-banner/Associate with DSA.png";
import Subscribe from "../../../assets/img/home/home-banner/Subscribe.png";
import "./ServiceSection.css";
import { useContext } from "react";
import { store } from "../../../Helpers/Store/Store";
export default function ServiceSection() {
    const {setScrollTodiv} = useContext(store)
    return (
        <section className="section-spacing pt-0 service-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-5">
                        <div className="heading-box d-flex justify-content-between ">
                            <h2 className="main-heading mb-0">
                                our <span>services</span>
                            </h2>
                            <Link to="/services">
                                View All
                                <FaArrowRight />
                            </Link>
                        </div>
                    </div>
                    <div className=" col-12  mb-5">
                        <div className="service-card">
                            <div className="image">
                                <img src={Subscribe} className="img-fluid" alt="" />
                            </div>
                            <div className="content">
                                <div className="text">
                                    <p className="name">
                                    Subscribe
                                    </p>
                                    <p className="des">
                                    <b> <i> DSA </i></b> offers both online and print magazines. Stay ahead with in-depth insights on global defence and security. Subscribe now for exclusive access to expert analysis and the latest updates!
                                    </p>





                                </div>
                                <div className="arrow-btn">
                                    <Link to="/services">
                                        <FaArrowRight />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="  col-12  mb-5">
                        <div className="service-card">
                            <div className="image">
                                <img src={Associate} className="img-fluid" alt="" />
                            </div>
                            <div className="content">
                                <div className="text">
                                    <p className="name">
                                    Associate with  <b> <i> DSA </i></b> 
                                    </p>
                                    <p className="des">
                                    Explore opportunities to partner with us, whether as a contributor, advertiser or collaborator. Let’s work together to advance the defence and security dialogue.
                                    </p>
                                    
                                </div>
                                <div className="arrow-btn">
                                    <Link to="/services" onClick={()=>setScrollTodiv("associate")}>
                                        <FaArrowRight />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}