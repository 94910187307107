import { useState } from "react";
import "./ServiceContactForm.css";
export default function ServiceContactForm() {
    const [name,setName] = useState("")
    const [email,setEmail] = useState("")
    const [mobile,setMobile] = useState(null)
    const [message,setMessage] = useState("")

    return (
        <div className="srvice-form-box">
            <div className="main-heading text-center mb-5">
                <h2>
                    Connect <span>with us</span>
                </h2>
                <p>contact via form below & we'll get back to you</p>
            </div>
            <div className="service-conact-form">
                <input type="text" placeholder="Name" value={name} onChange={(e)=>setName(e.target.value)} />
                <input type="text" placeholder="Mobile No" value={mobile} onChange={(e)=>setMobile(e.target.value)} />
                <input type="email" placeholder="Email" value={email} onChange={(e)=>setEmail(e.target.value)} />
                <textarea placeholder="TYPE YOUR CONCERN (Optional)" rows="5"
                value={message} onChange={(e)=>setMessage(e.target.value)}></textarea>
                <div className="text-center mt-4">
                    <button type="submit"> Submit</button>
                </div>
            </div>
        </div>
    )
}