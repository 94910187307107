import { Link } from "react-router-dom";
import "./LatestArticleSection.css";
export default function LatestArticleSection({ data }) {
    return (
        <section className=" latest-article-section">
            <div className="container-fluid">
                <div className="row">

                    <div className=" col-md-6 left-section order-2 order-md-1 ">

                    
                    <div className="top-section mt-1">
                        <h2 className="main-heading">
                            {data[0]?.fld_heading} 
                        </h2>
                    </div>

                    <div className="bottom-section ">
                        <p>
                            {data[0]?.fld_short_desc}
                            {/* <Link to={`/article/${data[0]?.fld_id}`}><span className="read-more-btn ms-2"> read more</span></Link> */}
                        </p>
                        
                        <Link to={`/article/${data[0]?.fld_id}`}><span className="read-more-btn ms-2"> Read more</span></Link>

                        <div className="d-flex flex-wrap gap-4 gap-lg-5 text-capitalize mt-3 mb-5">


                            <b>
                                {/* written by - {data[0]?.fld_published_by} */}
                            </b>
                            <b>
                                {/* Date - {moment(data[0]?.fld_createdon).format('MMMM D, YYYY')} */}
                            </b>
                            {/* <b>Time - {moment(data[0]?.fld_createdon).format('HH:mm:ss')}</b> */}
                        </div>
                    </div>

                    </div>

                    <div className="col-md-6 right-section order-1 order-md-2">
                        <Link to={`/article/${data[0]?.fld_id}`}>
                            <img src={data[0]?.fld_image} className="img-fluid" alt={data[0]?.fld_alt} />
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}