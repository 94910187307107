import "./TopSection.css";
import Image from "../../../../assets/img/digital-art-depicting-india-independence-day.jpg";
export default function TopSection() {
    return (
        <section className="section-spacing top-section-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-5 order-lg-1 order-2">
                        <div className="left-box">
                            {/* <img src={NoImg} className="img-fluid" alt="" /> */}
                            <div className="content">
                                <h2 className="main-heading">
                                    DEF- SEC EDU
                                </h2>
                                <p>
                                To prepare the next generation of leaders, experts and professionals, we initiated "Def-Sec-Education" as a dedicated platform for those interested in pursuing a career in this crucial field. The primary goal is to provide accessible, accurate and comprehensive resources to help individuals gain deeper understanding about the fundamentals of defence and security and inspire them to consciously contribute to national and global safety.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 order-lg-2 order-1">
                        <img src={Image} className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
        </section>
    )
}