import { Link } from "react-router-dom";
import "./InstagramFeed.css";
import { InstagramEmbed } from "react-social-media-embed";
import { Swiper,SwiperSlide } from "swiper/react";
 
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/navigation'; 
import 'swiper/css/scrollbar';


// import required modules
import { FreeMode,Navigation} from 'swiper/modules';

export default function InstagramFeed() {
    return (
        <section className="section-spacing px-0 pb-0 insta-feed-section ">
            {/* <div className="animation-text">
                testimonial
            </div> */}
            <div className="container-fluid ">
                <div className="row section-spacing  pb-0">
                    <div className="col-12">
                        <div className="d-lg-flex  py-0 mb-4 align-items-end heading-box justify-content-between">
                            <h2 className="main-heading">
                                <span>Instagram</span> Feed
                            </h2>
                            <Link to="" className="text-end">
                                @dsamagazine <br />
                                #DSA
                            </Link>
                        </div>
                    </div>

                    <Swiper
                            spaceBetween={20}
                            slidesPerView={2}
                            freeMode={true}
                            draggable={true}
                            navigation
                            loop={false}
                             pagination={{
                                clickable:"true"
                                
                            }}
                            // scrollbar={{draggable:true}}
                            breakpoints={{
                                319:{
                                    slidesPerView:1,
                                    spaceBetween:20,
                                },
                                380:{
                                    slidesPerView:1.1,
                                    spaceBetween:20,
                                },
                                576:{
                                    slidesPerView: 1.5,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 2.2,
                                    spaceBetween: 20,
                                },
                                1024: {
                                    slidesPerView: 2.5,
                                    spaceBetween: 20,
                                },
                                1200: {
                                    slidesPerView: 3.0,
                                    spaceBetween: 20,
                                },
                                1500: {
                                    slidesPerView: 4,
                                    spaceBetween: 20,
                                },
                                
                            }}
                            modules={[FreeMode,Navigation]}
                            className="mySwiper pt-5 pt-lg-0"
                        >

                 <SwiperSlide> 
                    <div className="col-lg-12  col-12  test ">
                    <InstagramEmbed url="https://www.instagram.com/p/DCV_SCnSPlO/?igsh=MWZicHRmZDZ3N2RyOA%3D%3D" />
                        {/* <img src={NoImg} className="img-fluid" alt="" /> */}
                    </div>
                    </SwiperSlide>

                    <SwiperSlide>

                    <div className="col-lg-12     col-12 ">
                    <InstagramEmbed url="https://www.instagram.com/p/DDyJEGIhW3P/?igsh=MTdpZHBlZnA4bnVkZA%3D%3D&img_index=1" />
                        {/* <img src={NoImg} className="img-fluid" alt="" /> */}
                    </div>
                    </SwiperSlide>

                    <SwiperSlide>

                    <div className="col-lg-12    col-12 ">
                    <InstagramEmbed url="https://www.instagram.com/p/DDyJEGIhW3P/?igsh=MTdpZHBlZnA4bnVkZA%3D%3D&img_index=1" />
                        {/* <img src={NoImg} className="img-fluid" alt="" /> */}
                    </div>
                    </SwiperSlide>

                    <SwiperSlide>

                    <div className="col-lg-12     col-12 ">
                    <InstagramEmbed url="https://www.instagram.com/p/DDv6mYuSKd0/?igsh=ZDJmdmUzYTltY2Rv&img_index=1" />
                        {/* <img src={NoImg} className="img-fluid" alt="" /> */}
                    </div>
                    </SwiperSlide>

                    <SwiperSlide>

                    <div className="col-lg-12     col-12  mb-lg-0">
                    <InstagramEmbed url="https://www.instagram.com/p/DDQp_o-y2Jl/?igsh=MWQ5cHAwcTJpdXU0ZA%3D%3D&img_index=1" />
                        {/* <img src={NoImg} className="img-fluid" alt="" /> */}
                    </div>
                    </SwiperSlide>
                    </Swiper>





                    <Swiper
                            spaceBetween={20}
                            slidesPerView={2}
                            freeMode={true}
                            navigation
                            draggable={true}
                            loop={false}
                             pagination={{
                                clickable:"true"
                                
                            }}
                            // scrollbar={{draggable:true}}
                            breakpoints={{
                                320:{
                                    slidesPerView:1,
                                    spaceBetween:20,
                                },
                                380:{
                                    slidesPerView:1.1,
                                    spaceBetween:20,
                                },
                                576:{
                                    slidesPerView: 1.5,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 2.2,
                                    spaceBetween: 20,
                                },
                                1024: {
                                    slidesPerView: 2.5,
                                    spaceBetween: 20,
                                },
                                1200: {
                                    slidesPerView: 3.0,
                                    spaceBetween: 20,
                                },
                                1500: {
                                    slidesPerView: 4,
                                    spaceBetween: 20,
                                },
                                
                            }}
                            modules={[FreeMode,Navigation]}
                            className="mySwiper pt-4 pt-lg-0"
                        >

 
                    <SwiperSlide>
                    <div className="col-lg-12    col-12 mb-lg-0">
                    <InstagramEmbed url="https://www.instagram.com/p/DDd4wn3yQll/?igsh=ZXhtd3MxZ3h0YjB4&img_index=1" />
                        {/* <img src={NoImg} className="img-fluid" alt="" /> */}
                    </div>
                    </SwiperSlide>

                    <SwiperSlide>
                    <div className="col-lg-12 col-12  mb-lg-0">
                    <InstagramEmbed url="https://www.instagram.com/p/DDb0vm8Seq1/?igsh=bXRtcDhzYzIyMDE4&img_index=1" />
                        {/* <img src={NoImg} className="img-fluid" alt="" /> */}
                    </div>
                    </SwiperSlide>

                    <SwiperSlide>
                    <div className="col-lg-12  col-12   mb-lg-0">
                    <InstagramEmbed url="https://www.instagram.com/p/DDZdZGxSQYt/?igsh=MTV5Z2h4eTcwNG0zaQ%3D%3D&img_index=1" />
                        {/* <img src={NoImg} className="img-fluid" alt="" /> */}
                    </div>
                    </SwiperSlide>

                    <SwiperSlide>
                    <div className="col-lg-12  col-12   mb-lg-0">
                    <InstagramEmbed url="https://www.instagram.com/p/DDZdZGxSQYt/?igsh=MTV5Z2h4eTcwNG0zaQ%3D%3D&img_index=1" />
                        {/* <img src={NoImg} className="img-fluid" alt="" /> */}
                    </div>
                    </SwiperSlide>
                    
                    </Swiper>

                    


                    {/* <div className="col-lg-3 col-md-3   col-sm-4   col-4 mb-3 mb-lg-0"> */}
                    {/* <InstagramEmbed url="https://www.instagram.com/p/DDZdZGxSQYt/?igsh=MTV5Z2h4eTcwNG0zaQ%3D%3D&img_index=1" /> */}
                        {/* <img src={NoImg} className="img-fluid" alt="" /> */}
                    {/* </div> */}

                    

                </div>
            </div>
        </section>
    )
}