import  "./PrivacyPolicy.css";
export default function PrivacyPolicy() {
    
    return(
        <div className="container-fluid wrapper">
            
            <h2 className="text-left my-4">Privacy Policy</h2>
            <p>The Privacy Policy of <strong>Defence and Security Alert (<em>DSA</em>) </strong>outlines the collection and use of personal information such as names and email addresses solely for communication purposes and to enhance user experience. This data is never sold or shared with third parties. The website may use cookies to improve usability, though users have the option to disable cookies in their browser settings. While <strong><em>DSA</em></strong> prioritises the protection of user data, it cannot guarantee complete security due to the nature of internet transmission. External links may appear on the site, but <strong><em>DSA</em></strong> is not responsible for their privacy practices. Users are encouraged to review the Privacy Policy periodically for updates.</p>
<p><strong>Comments:</strong> If you leave a comment, the comment and its metadata are retained indefinitely to allow automatic approval of follow-up comments instead of placing them in a moderation queue.</p>
<p><strong>User Accounts:</strong> For registered users, the personal information provided is stored in their user profile. Users can view, edit, or delete their personal information at any time, except for their username. Website administrators can also access and edit this information.</p>
<p><strong>Data Requests:</strong> Users with accounts or comments on the site can request an exported file of their personal data or request data erasure. This excludes data retained for administrative, legal, or security purposes.</p>


<h3 className="text-left my-4">Print and Digital Subscription</h3>
<p>By subscribing to <strong><em>DSA</em></strong> Magazine, you agree to receive print and/or digital access to the Magazine, including all associated content, features, and services.</p>
<p><strong>Subscription Terms:</strong> Subscriptions are granted upon confirmation of full advance payment and begin from the payment date, continuing for the selected subscription period. For one year, two years or three years as the case may be.</p>
<p><strong>Fees:</strong> You agree to pay the subscription fee for the chosen term. Fees for multi-term subscriptions are charged in full at purchase unless specified otherwise. The Publisher may change subscription prices and you will be notified of price changes before your next billing cycle.</p>
<p><strong>Delivery:</strong> Print and digital issues will be sent to the mailing address or email address provided during the subscription. Ensure your details are accurate for timely delivery.</p>


<h3 className="text-left my-4">No Cancellation and Refund Policy</h3>
<p>Once purchased, subscriptions are non-cancellable and non-refundable for any reason, including changes in personal circumstances, dissatisfaction with content, or delivery issues. Payments made for subscriptions, special editions, or multi-issue plans are non-refundable. Refunds will not be issued for early cancellations or if a user decides they no longer wish to receive the magazine. This policy is clearly established to ensure transparency.</p>


<h3 className="text-left my-4">Terms and Conditions</h3>
<p>By accessing or using the <strong>Defence and Security Alert</strong> (<strong><em>DSA</em></strong>) website, users agree to the following terms:</p>
<ul>
<li><strong>Content Ownership:</strong> All website content, including text, graphics, logos and images, is the exclusive property of <strong><em>DSA</em></strong> and is protected by copyright law. Unauthorised use, reproduction, distribution, or modification is prohibited.</li>
<li><strong>Lawful Use:</strong> The website must be used for lawful purposes that do not infringe on the rights of others or harm the platform. Activities compromising website security or unauthorised access to restricted areas are prohibited.</li>
<li><strong>Liability:</strong> <strong><em>DSA</em></strong> assumes no liability for damages arising from website use and users acknowledge that usage is at their own risk.</li>
<li><strong>Updates:</strong> Terms and conditions may be updated at any time and continued use of the website implies acceptance of these updates.</li>
</ul>
<p><strong>Content Submission Guidelines</strong></p>
<ul>
<li>Only Original and unpublished content should be shared.</li>
<li>Contributions must align with topics related to defence, security and international affairs pertaining to India and globally.</li>
<li>Credit sources may appropriately be mentioned when sharing external material.</li>
<li>Avoid plagiarism, AI and respect copyright and trademark rights.</li>
<li>Refrain from sharing misleading or inaccurate information.</li>
<li>Editorial team has the liberty/prerogative to approve or disapprove any received content for publication in <em>DSA</em> and no further discussions are acceptable in case of disapproval of any shared content.</li>
</ul>
<p><strong>Subscription and Delivery</strong></p>
<p><strong><em>DSA</em></strong> is committed to ensure timely and secure delivery of print subscriptions. Below are the details of our shipping and delivery processes:</p>
<ul>
<li><strong>Processing Time:</strong> Orders are processed within 3-5 business days after subscription confirmation (excluding weekends and holidays).</li>
<li><strong>Shipping Time:</strong>
<ul>
<li><strong>Within India:</strong> Delivery within 7 to 10 business days, depending on location.</li>
<li><strong>International orders:</strong> Delivery within 2 to 4 weeks, varying by destination.</li>
</ul>
</li>
<li><strong>Subscription Costs:</strong>
<ul>
<li><strong>Within India:</strong> Free for annual subscriptions ₹2080 including shipping charge.</li>
<li><strong>International:</strong> US $924 per year. Price may vary by country location.</li>
<li><strong>SAARC Counties:</strong> US $770 per year. Prices may vary by country location.</li>
</ul>
</li>
</ul>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ul>
<li><strong>Delivery Method:</strong>
<ul>
<li><strong>Within India:</strong> Subscriptions sent via local courier services / Indian Postal Service.</li>
<li><strong>International:</strong> Subscriptions sent via international postal carriers or expedited shipping services <strong>/</strong> Indian Postal Service.</li>
</ul>
</li>
</ul>
<p>&nbsp;</p>
<p><strong>Contact Us</strong></p>
<p>For subscription, advertising, or support queries, contact us via:</p>
<ul>
<li><strong>Email: </strong><u>subscription@ dsalert.org</u> <u>/ info@dsalert.org</u></li>
<li><strong>Phones:</strong> 011-4163499, +91 9958382999</li>
<li><strong>Postal Address:</strong> 4/19, Asaf Ali Road, New Delhi 110002</li>
</ul>
<p>Our support team strives to respond within 48 business hours (Monday to Saturday).</p></div>
    )
}