 import React from 'react'
 import  "./Faq.css";
 
 const Faq = () => {
   return (
     <div  className="container-fluid faq-section my-4"> 
     <h2> Frequently Asked Questions (FAQ)</h2>

     
<h4 className='text-left my-4'>General Information</h4>

<h5>1. What is a  <strong> Defence and Security Alert(<em>DSA</em>) </strong> ?</h5>
<p><strong>Defence and Security Alert <em>(DSA)</em> </strong>is India’s first <strong>ISO 9001:2015 </strong>
    certified monthly magazine focused on defence, security, and international affairs. 
    Established in October 2009 and published by Ocean Media Private Limited, it provides
     research-driven insights and has been available on the Indian Air Force INTRANET for
      the past eight years.</p>
<h5> 2. Who are the key audiences for <strong> <em>DSA</em></strong>?</h5>
<p><strong><em>DSA</em></strong> is read by policy and decision makers, military professionals, researchers, and enthusiasts in the defence and security sectors, both in India and Globally.</p>
<h5>3. What is the mission and vision of  <strong> <em>DSA</em></strong>?</h5>
<p><strong><em>DSA</em></strong> aims to raise awareness on matters related to defence, security, and global peace challenges through well-researched content. It seeks to inform stakeholders to foster understanding and proactive responses to evolving global threats.</p>

{/* General Information */}
<h5>4. What sets  <strong> <em>DSA</em></strong> apart from other defence magazines?</h5>
<p>DSA is India’s only <strong> ISO 9001:2015 </strong>certified defence magazine and the sole publication available on the Indian Air Force INTRANET for over eight years. Its unique combination of expert perspectives, research-based analysis, and exclusive interviews with top defence leaders positions <strong> <em>DSA</em></strong> as a trusted authority in the field.</p>
<h5>5. How does   <strong> <em>DSA</em></strong> ensure the credibility of its content?</h5>
<p>DSA features content crafted by experienced experts from military, security, and geopolitical backgrounds. All articles are rigorously researched and reviewed to maintain high standards of accuracy, insight, and reliability.</p>








         
<h4 className='text-left my-4'>Magazine and Content</h4>
<h5>1. What topics does  <strong> <em>DSA</em></strong> cover?</h5>
<p> <strong> <em>DSA</em></strong> covers a wide range of topics including military affairs, cyber security, geopolitics, counter-terrorism, drug nexus, and human trafficking. Special editions focus on specific themes such as the Indian Armed Forces, cyber security, and the defence budget.</p>
<h5>2. How often is the magazine published?</h5>
<p> <strong> <em>DSA</em></strong> is published monthly, with each edition offering in-depth insights into current and emerging issues in global and regional defence and security.</p>
<h5>3. Can I read  <strong> <em>DSA</em></strong> online?</h5>
<p>Yes, <strong> <em>DSA</em></strong> offers both online and print subscriptions. You can subscribe to gain exclusive access to expert analysis and real-time updates.</p>
<h5>4. Are there free resources available?</h5>
<p>Yes, you can access a selection of free articles from <strong> <em>DSA</em></strong> to experience the quality of content provided. Additionally, security awareness alerts and newsletters are available to keep you informed on the latest in defence and security.</p>

<h5>5. Does  <strong> <em>DSA</em></strong> cover international military affairs?</h5>
<p>Yes, while  <strong> <em>DSA</em></strong> maintains a strong focus on India, it covers significant international defence and security issues, including global conflicts, geopolitical developments, and military technology advancements.</p>
<h5>6. How can I access past issues of  <strong> <em>DSA</em></strong>?</h5>
<p>Subscribers have access to an archive of past issues available online. Non-subscribers can purchase individual back issues through the  <strong> <em>DSA</em></strong> website.</p>
<h5>7. Can I suggest topics for future  <strong> <em>DSA</em></strong> editions?</h5>
<p>Yes, <strong> <em>DSA</em></strong> welcomes suggestions from readers. You can send topic suggestions via the contact form on our website or email us directly at <strong>contact@dsa.in.</strong></p>
<h5>8. What special features does <strong> <em>DSA</em></strong> provide during key events like Defence Day or Independence Day?</h5>
<p> <strong> <em>DSA</em></strong> publishes special anniversary issues and editions themed around significant national events such as Indian Air Force Day and Republic Day, featuring exclusive interviews, historical reflections, and articles on defence advancements.</p>







<h4 className='text-left my-4'>Subscriptions and Partnerships</h4>
<h5>1. How do I subscribe to  <strong> <em>DSA</em></strong>?</h5>
<p>You can subscribe to  <strong> <em>DSA</em></strong> through their official website, choosing either an online or print version based on your preference. Subscriptions provide exclusive access to high-quality analysis and timely updateson our social media network such as LinkedIn, Twitter, Facebook and WhatsApp group as well. </p>
<h5>2. How can I advertise in  <strong> <em>DSA</em></strong>?</h5>
<p> <strong> <em>DSA</em></strong> offers tailored advertising packages that connect your brand with key decision-makers in the defence sector. Reach out to their advertising team via their contact details to explore these opportunities, for more information contact us!</p>
<h5>3. Are there sponsorship opportunities with <strong> <em>DSA</em></strong>?</h5>
<p>Yes, <strong> <em>DSA</em></strong> offers sponsorship opportunities for brands to support its initiatives and gain recognition across  <strong> <em>DSA’s</em></strong> platforms. Sponsors benefit from exclusive visibility and association with excellence in defence journalism.</p>




<h4 className='text-left my-4'>Engagement and Contributions</h4>
<h5>1. Can I contribute to  <strong> <em>DSA</em></strong> as a writer?</h5>
<p> <strong> <em>DSA</em></strong> welcomes articles and perspectives from experts, enthusiasts, and storytellers in defence and security. Contributors have a chance to share their viewpoints and influence discussions in these fields.</p>
<h5>2. What is ‘Def Sec Wives’?</h5>
<p>‘Def Sec Wives’ is a section dedicated to the wives of defence personnel, celebrating their strength, sacrifices, and creativity. It features stories, hobbies, and experiences from these resilient women.</p>
<h5>3. Does  <strong> <em>DSA</em></strong> offer podcasts?</h5>
<p>Yes,  <strong> <em>DSA</em></strong> provides podcasts that delve into defence, security, and international affairs, designed to educate and inspire listeners on these important topics.</p>





<h4 className='text-left my-4'>Contact and Support</h4>
<h5>1. How can I contact  <strong> <em>DSA</em></strong>?</h5>
<p>For partnership and sponsorship inquiries, you can contact <strong><em> DSA’s </em> </strong>sponsorship team at <strong> BD@oceanmedia.in </strong> or <strong>call +91 9958382999.</strong></p>
<h5>2. How can I get updates on current affairs in defence and security?</h5>
<p>Subscribing to <strong><em> DSA’s </em> </strong>newsletter will provide real-time alerts and updates on defence and security topics tailored to your preferred regions, such as Asia, Europe, or North America.</p>





{/* <h4 className='text-left my-4'>Magazine and Content</h4> */}




<h4 className='text-left my-4'>Subscriptions and Partnerships</h4>
<h5>1. Are there discounts available for students or group subscriptions?</h5>
<p> <strong> <em>DSA</em></strong> offers discounts for students, academic institutions, and group subscriptions. Contact the <strong> <em>DSA</em> </strong>team at <strong>subscriptions@dsa.in</strong> for details on eligibility and rates.</p>
<h5>2. Can I purchase a one-time access pass instead of a full subscription?</h5>
<p>Yes,  <strong> <em>DSA</em></strong> offers one-time access passes for specific articles or single issues, allowing you to explore individual content without a full subscription.</p>
<h5>3. Are subscriptions available internationally?</h5>
<p>Yes,  <strong> <em>DSA</em></strong> subscriptions are available worldwide. International subscribers can choose digital subscriptions or request international shipping for print editions, which may incur additional shipping fees.</p>
<h5>4. How do I upgrade my subscription?</h5>
<p>You can upgrade your subscription plan at any time by logging into your  <strong> <em>DSA</em></strong> account and selecting the upgrade option. Any additional charges will be processed at the time of upgrade.</p>


<h4 className='text-left my-4'>Engagement and Contributions</h4>
<h5>1. Can I submit photos or artwork for publication in  <strong> <em>DSA</em></strong>?</h5>
<p>Yes,  <strong> <em>DSA</em></strong> welcomes photos and artwork related to defence and security topics. If you’re a photographer or artist with relevant work, send your submissions to <strong>contributions@dsa.in</strong>, along with a brief description.</p>
<h5>2. Does  <strong> <em>DSA</em></strong> conduct interviews or host events with defence experts?</h5>
<p>Yes,  <strong> <em>DSA</em></strong> frequently features interviews with defence and security leaders and hosts seminars, webinars, and live discussions. Keep an eye on the <strong> <em>DSA</em> </strong> website and social media for event announcements.</p>
<h5>3. How can I become a regular contributor to  <strong> <em>DSA</em></strong>?</h5>
<p>If you are a defence expert, researcher, or seasoned writer in security and geopolitics, reach out to us at <strong>contributions@dsa.in</strong> with a sample of your work. We welcome contributors who can provide in-depth and unique perspectives.</p>





<h4 className='text-left my-4'>Defence Community and Careers</h4>
<h5>1. Does  <strong> <em>DSA</em></strong> offer career advice for those interested in joining the armed forces?</h5>
<p>Yes,  <strong> <em>DSA</em></strong> regularly features articles and special sections offering guidance on career paths in the Indian Armed Forces, as well as tips on exams, training requirements, and opportunities in defence-related fields.</p>
<h5>2. Are there networking opportunities available for  <strong> <em>DSA</em></strong> subscribers?</h5>
<p><strong><em>DSA</em></strong> subscribers can participate in exclusive networking events, forums, and webinars hosted by  <strong> <em>DSA</em></strong>, where they can interact with professionals and experts in defence and security.</p>


<h4 className='text-left my-4'>Contact and Support</h4>
<h5>1. How can I report an issue with my subscription or delivery?</h5>
<p>For any subscription or delivery issues, contact our support team at <strong>support@oceanmedia.in</strong> or<strong> call +91 9958382999.</strong> Our team will assist you with resolving the issue promptly.</p>
<h5>2. How can I stay updated on  <strong> <em>DSA</em></strong> events and releases?</h5>
<p>Follow  <strong> <em>DSA</em></strong> on social media platforms (Facebook, Twitter, LinkedIn) and subscribe to our newsletter to receive notifications about upcoming events, new issues, and exclusive content releases.</p>
<h5>3. Where can I provide feedback or reviews for  <strong> <em>DSA</em></strong> content?</h5>
<p>We welcome your feedback! Share your thoughts with us through our website’s feedback form or email feedback@dsa.in. Reviews and testimonials are also welcome on <strong><em>DSA</em>’s</strong> social media pages.</p>

























<div className='term-and-conditions'>

<h4 className='text-left my-4'>Terms and Conditions for  <strong> <em>DSA</em></strong> Magazine Subscribers</h4>

<p>Welcome to<strong> Defence and Security Alert <em>(DSA)</em></strong>! Please carefully review the following Terms and Conditions, which govern all subscriptions. By subscribing, you agree to abide by these terms.  <strong> <em>DSA</em></strong> reserves the right to amend these terms at any time, with updated versions available on our website.</p>
<h5 className='top-spacing'>1. Subscription Services and Plans</h5>
<p>•	Subscription Options:  <strong> <em>DSA</em></strong> offers monthly, annual, and special-edition subscription options for both digital and print formats. Details of each plan, including benefits and access privileges, are available on our subscription page.</p>
<p>•	Subscription Start Date: Your subscription begins on the date of purchase or on the start date specified in your account confirmation email.</p>

<h5 className='top-spacing'>2. Billing and Payment</h5>
<p>•	Fees and Payment: Subscription fees are charged at the beginning of each term and must be paid in full. Payments are processed securely via our third-party payment providers.</p>
<p>•	Auto-Renewal: Subscriptions automatically renew at the end of each term unless cancelled. You will be notified of the renewal and charged the then-current subscription fee.</p>
<p>•	Refund Policy: Refunds are available within 7 days of the initial purchase if no content has been accessed. For annual renewals, refunds are considered on a case-by-case basis.</p>
<h5 className='top-spacing'>3. Cancellations and Changes</h5>
<p>•	Cancellation by Subscriber: Subscribers may cancel any time through their account settings. Cancellations take effect at the end of the current billing cycle, and you retain access to content until the subscription term concludes.</p>
<p>•	Modifications by  <strong> <em>DSA</em></strong>:  <strong> <em>DSA</em></strong> reserves the right to change or terminate the terms of subscription plans, including pricing and benefits. Significant changes will be communicated to subscribers via email.</p>
<h5 className='top-spacing'>4. Subscriber Account and Security</h5>
<p>•	Account Responsibility: Subscribers are responsible for maintaining the confidentiality of their account information, including their username and password. Unauthorized sharing of login details is strictly prohibited.</p>
<p>•	Account Information: Please keep your personal and contact information up-to-date to ensure uninterrupted access to  <strong> <em>DSA</em></strong> services.  <strong> <em>DSA</em></strong> is not responsible for any issues arising from outdated or inaccurate subscriber information.</p>
<h5 className='top-spacing'>5. Usage and Content Rights</h5>
<p>•	Personal Use Only: Subscription content is for personal, non-commercial use only. Subscribers may not copy, distribute, or republish  <strong> <em>DSA</em></strong> content without express written permission.</p>
<p>•	Intellectual Property: All content, including text, graphics, logos, and images, is the property of <strong> <em>DSA</em></strong> and its contributors and is protected by copyright and intellectual property laws. Unauthorized use of content is prohibited and may result in termination of your subscription.</p>
<h5>6. Delivery of Print Editions</h5>
<p>•	Shipping: For print subscriptions,  <strong> <em>DSA</em></strong> delivers to the address provided during signup. Delivery schedules depend on location, and  <strong> <em>DSA</em></strong> is not responsible for delays due to unforeseen circumstances.</p>
<p>•	Undelivered Copies: If a print issue is not received, contact our customer support team within 14 days to request a replacement. Replacement issues are subject to availability.</p>
<h5 className='top-spacing'>7. Digital Access and Restrictions</h5>
<p>•	Device Compatibility: Digital subscriptions require compatible devices, browsers, and software.  <strong> <em>DSA</em></strong> is not responsible for any lack of functionality due to unsupported devices or software.</p>
<p>•	Sharing of Digital Content: Sharing or redistributing digital content with non-subscribers is not permitted. Subscribers may be held liable for damages resulting from unauthorized sharing.</p>
<h5 className='top-spacing'>8. Content Accuracy and Availability</h5>
<p>•	Content Disclaimer:  <strong> <em>DSA</em></strong> strives to ensure content accuracy but does not guarantee completeness, reliability, or suitability for specific purposes. Subscribers are advised to independently verify critical information.</p>
<p>•	Service Interruptions: While  <strong> <em>DSA</em></strong> makes every effort to ensure continuous service, we are not liable for interruptions due to technical issues, maintenance, or factors beyond our control.</p>
<h5 className='top-spacing'>9. Privacy and Data Protection</h5>
<p>•	Data Collection:  <strong> <em>DSA</em></strong> collects and uses subscriber data as described in our Privacy Policy. By subscribing, you agree to the terms of our Privacy Policy.</p>
<p>•	Marketing Communications: Subscribers consent to receive newsletters and updates. You may opt out of marketing communications at any time without affecting your subscription status.</p>
<h5 className='top-spacing'>10. Liability Limitation and Dispute Resolution</h5>
<p>•	Limitation of Liability:  <strong> <em>DSA</em></strong> is not liable for any direct, indirect, incidental, or consequential damages arising from the use of our content or services.</p>
<p>•	Governing Law: These Terms and Conditions are governed by the laws of India. Any disputes will be resolved in the courts of New Delhi.</p>
<p>•	Dispute Resolution: In the event of a dispute, subscribers agree to attempt resolution informally with  <strong> <em>DSA</em></strong> customer service before seeking legal remedies.</p>


<h5 className='top-spacing'>11. Contact Us</h5>
<p>•	For any questions or concerns about these Terms and Conditions, please contact us at <strong> support@oceanmedia.in </strong> or  <strong>call +91 9958382999.</strong></p>

 
</div>
       
     </div>
   )
 }
 
 export default Faq
 