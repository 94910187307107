import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { FaWhatsapp, FaXTwitter } from "react-icons/fa6";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
export default function SocialShare({shareUrl}) {
    return (
        <>
                                {/* <li>
                                    <FacebookShareButton url={shareUrl}  >
                                        <FaSquareInstagram />
                                    </FacebookShareButton>
                                </li> */}
                                <li>
                                    <FacebookShareButton url={shareUrl}  >
                                        <FaFacebookF />
                                    </FacebookShareButton>
                                </li>
                                <li>
                                    <TwitterShareButton url={shareUrl}  >
                                        <FaXTwitter />
                                    </TwitterShareButton>
                                </li>
                                <li>
                                    <LinkedinShareButton url={shareUrl}  >
                                        <FaLinkedinIn />
                                    </LinkedinShareButton>
                                </li>
                                <li>
                                    <WhatsappShareButton url={shareUrl}  >
                                        <FaWhatsapp />
                                    </WhatsappShareButton>
                                </li>
                                {/* <li><a href="http://" target="_blank" rel="noopener noreferrer"><FaYoutube /></a></li> */}
         </>
    )
}