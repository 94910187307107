import { Link } from "react-router-dom";
import Arrow from "../../../assets/img/icons/Vector.png";
import "./PodcastSection.css";
export default function PodcastSection({ banners }) {
    return (
        <section className="section-spacing px-lg-5 podcast-section">
            <div className="container-fluid">
                <div className="row justify-content-lg-between">
                    <div className="col-lg-7 col-md-7  left-section">
                        <div className="top-section">
                            <div className="img"></div>
                            {/* <img src={NoImg} className="img-fluid" alt="" /> */}
                            <div className="content">
                                <div className="text">
                                    <h2 className="main-heading">
                                    Stay Vigilant, Stay Awake 
                                    </h2>
                                    <p>
                                    Our podcasts deliver expert insights and stories to empower our generation and bring awareness in the society. These podcasts fuel personal and professional growth, aligning with <b> <i> DSA's </i></b>  vision to keep you ahead in the genre of defence, security and international affairs.
                                    </p>
                                    <img src={Arrow} className="img-fluid" alt="" />
                                </div>
                                <div className="youtube-btn mt-5">
                                    <Link to="https://www.youtube.com/">youtube channel</Link>
                                </div>
                            </div>
                        </div>
                        <div className="bottom-two-cards">
                            <div className="b-card">
                                <img src="https://dsalert.org/images/home/15.webp" className="img-fluid" alt="" />
                                {/* <div>
                                    <span>on June 8, 2020</span>
                                    <p>#Loremipsum</p>
                                </div> */}
                            </div>
                            <div className="b-card">
                                <img src="https://dsalert.org/images/home/16.webp" className="img-fluid" alt="" />
                                {/* <div>
                                    <span>on June 8, 2020</span>
                                    <p>#Loremipsum</p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className=" col-lg-5 col-md-5 ">
                        <div className="right-card">
                            <img src={banners[0]?.fld_image} alt="" className="img-fluid" />
                            {/* <div>
                                <span>{banners[0]?.fld_created_by}</span>
                                <p>{banners[0]?.fld_short_desc}</p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}