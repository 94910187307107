import moment from "moment";
import "./RelatedNews.css";
import { Link, useNavigate } from "react-router-dom";
export default function RelatedNews({ data }) {
    let navigate = useNavigate();
    return (
        <section className="section-spacing pt-0 related-news-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h2 className="main-heading">
                            <span>Related</span>  News
                        </h2>
                    </div>
                    {
                        data?.map((item, id) => {
                            return (
                            <div className="col-lg-3 edition-card cursor-pointer" key={id}>
                                <div className="box" onClick={()=>navigate(`/news/${item?.fld_id}`)}>
                                    <div className="date">
                                        {/* //cannot find proper key */}
                                        <p>on {moment(item?.fld_createdon).format('MMMM D, YYYY')}</p>
                                        <p>{item?.fld_type}</p>
                                    </div>
                                    <div className="image">
                                        <img src={item?.fld_image} className="img-fluid" alt={item?.fld_alt} />
                                    </div>
                                    <h6> {item?.fld_heading}</h6>
                                    {/* not sure if its the correct key */}
                                    <p className="desc" dangerouslySetInnerHTML={{ __html: item?.fld_long_desc }} >
                                    </p>
                                    
                                </div>
                            </div>)
                        })
                    }
                    <div className="col-12 text-center mt-5">
                        <Link to="/newsletter-news" className="theme-btn">Back to Categories</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}