import { useContext, useEffect } from "react";
import AboutGallerySection from "../Components/AboutUs/AboutGallerySection/AboutGallerySection";
import AboutPageHeader from "../Components/AboutUs/AboutPageHeader/AboutPageHeader";
import CeoSection from "../Components/AboutUs/CeoSection/CeoSection";
import DsaUps from "../Components/AboutUs/DsaUps/DsaUps";
import FounderSection from "../Components/AboutUs/FounderSection/FounderSection";
import JourneySection from "../Components/AboutUs/JourneySection/JourneySection";
import VisionSection from "../Components/AboutUs/VisionSection/VisionSection";
// import GroupWebsites from "../Components/Home/GroupWebsites/GroupWebsites";
import MilestoneSection from "../Components/Home/MilestoneSection/MilestoneSection";
import { store } from "../Helpers/Store/Store";
// import TestimonialSection from "../Components/Home/TestimonialSection/TestimonialSection";


export default function AboutUs() {
    const {scrollTodiv,setScrollTodiv} = useContext(store)
    useEffect(()=>{
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },[])
    useEffect(() => {
        if (scrollTodiv) {
          const targetDiv = document.getElementById(scrollTodiv);
          if (targetDiv) {
            const rect = targetDiv.getBoundingClientRect();
            const targetScrollTop = rect.top + window.pageYOffset;
    
            const scrollStep = (targetScrollTop - window.pageYOffset) / 20; // Divide the scroll into 20 steps
            let currentScrollTop = window.pageYOffset;
    
            const smoothScroll = () => {
              if (
                (scrollStep > 0 && currentScrollTop < targetScrollTop) ||
                (scrollStep < 0 && currentScrollTop > targetScrollTop)
              ) {
                currentScrollTop += scrollStep;
                window.scrollTo({
                  top: currentScrollTop,
                  behavior: "auto", // Disable smooth scrolling for this operation
                });
                requestAnimationFrame(smoothScroll);
              } else {
                window.scrollTo({
                  top: targetScrollTop,
                  behavior: "smooth", // Re-enable smooth scrolling for final step
                });
                setScrollTodiv(null);
              }
            };
    
            smoothScroll();
          }
        }
        // eslint-disable-next-line
      }, [scrollTodiv]);
    return (
        <div className="about-us-page">
            <AboutPageHeader />
            <MilestoneSection HeadingText={"15 years Journey so far through the key milestones that have defined DSA’s growth and impact in defence journalism. From our inception to becoming a globally recognized research based publication, see how we’ve evolved over the years."} />
            <VisionSection />
            <DsaUps />
            <FounderSection /> 
            <CeoSection />
            <JourneySection />
            <AboutGallerySection />
        </div>
    )
}