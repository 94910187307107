import "../MagazinePage/EditionsPage/EditionsCategories/EditionsCategories.css";
import "./SelectEditions.css"

import {useState, useEffect} from 'react'
import { Link } from "react-router-dom";
import PostApiCall from "../../Helpers/Api/PostApi";
import { Offcanvas } from "react-bootstrap";
import Payments from "../Payments/Payments";
import { RiDeleteBin5Fill } from "react-icons/ri";

export default function SelectEditions({data}) {
    const [filteredData, setFilteredData] = useState(data || []);
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [cartItems,setCartItems] = useState(JSON.parse(localStorage.getItem("itemsInCart")) || [])

    const getMagazinesEditionData = () => {
        PostApiCall.postRequest(
            {
            whereClause:""
            },
            "GetEditions"
          ).then((results) => {
            results.json().then((obj) => {
              if (results.status === 200 || results.status === 201) {
                setFilteredData(obj.data)
              } else {
              }
            });
          });
      }

      useEffect(()=>{
        getMagazinesEditionData();
      },[])
   const getFilteredData = (type) => {
    if(type === ""){
        setFilteredData(data);
    }else{
        // alert(type)
        const filteredItems = data?.filter(
            (item) => item?.fld_type === type && item?.fld_status === "Active"
        );
        setFilteredData(filteredItems);

    }
   }

   useEffect(() => {    
    setFilteredData(data || []); 
}, [data]);




    const handleAnnualEditionsYear=(event)=>{
         let AnnualEditionsYear=event.target.value;
         
         console.log("AnnualEditionsYear value "+AnnualEditionsYear)
         
    }
    const handleAnnualEditionsMonth=(event)=>{
        let AnnualEditionsMonth =event.target.value;
        console.log("AnnualEditionsMonth value "+ AnnualEditionsMonth)
    }
    // const handleAnniversaryEditionsMonth=(event)=>{
    //     let AnniversaryEditionsMonth=event.target.value;
    //     console.log( "AnniversaryEditionsMonth"+AnniversaryEditionsMonth);

    // }

    const handleItemToLocal = (item) => {
        // existing local data
        const existingItems = JSON.parse(localStorage.getItem("itemsInCart")) || [];
    
        // check for spare item in existing data
        const itemIndex = existingItems.findIndex((spare) => spare.editionid === item?.fld_id);
        if (itemIndex >= 0) {
        } else {
          // new item to localstorage
          existingItems.push({
            "image":item?.fld_image,
            "editionid":item?.fld_id,
            "description":item?.fld_heading,
            "price":1.25,
          })
        }
        localStorage.setItem("itemsInCart", JSON.stringify(existingItems));
        setCartItems(existingItems)
    };

    return (
        <section className="section-spacing py-0  editions-category-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12" onClick={()=>setShowOffcanvas(true)}>Cart - {cartItems.length}</div>
                    <div className="col-12">
                        <div className="category-tab">
                            <p>
                                Categories
                            </p>
                            <ul>
                                {/* <li onClick={() => getFilteredData('annualedition')}>
                                    Annual Editions
                                </li>
                                <li onClick = {()=> getFilteredData('anivarsaryedition')}>
                                    Anniversary Editions
                                </li> */}
                                {/* <li onClick = {()=> getFilteredData('')}>
                                    All
                                </li> */}
                            </ul>
                        </div>
                    </div>




                    <div className="container-fluid select-edition">

                           <form  className="AnnualEditionsYear">

                            <label className="mx-3">
                               
                            <select>
                            <option selected >Select Edition</option>
                              <option value="Anniversary Editions">Anniversary Editions</option>
                             <option value="Annual Editions">Annual Editions</option>
                             
                            </select>

                            </label>

                          <label>
                          <select onChange={handleAnnualEditionsYear}>
                            <option selected >Select Year</option>
                              <option value="2024">2024</option>
                             <option value="2023">2023</option>
                             <option value="2022">2022</option>
                             <option value="2021">2021</option>
                             <option value="2020">2020</option>
                             <option value="2019">2019</option>
                             <option value="2018">2018</option>
                             <option value="2017">2017</option>
                            </select>

                          
                           
                         </label>

                         <label className="mx-3">
                           
                         <select onChange={handleAnnualEditionsMonth}>
                         <option selected >Select Month</option>
                              <option  value="Jan">January</option>
                              <option value="Feb">February</option>
                              <option  value="Mar">March</option>
                              <option  value="Apr">April</option>
                              <option  value="May">May</option>
                              <option  value="Jun">June</option>
                              <option  value="Jul">July</option>
                              <option  value="Aug">August</option>
                              <option  value="Sep">September</option>
                              <option  value="Oct">October</option>
                              <option  value="Nov">November</option>
                              <option  value="Dec">December</option>
                              
                              </select>
                              
                             
                         </label>


                         {/* <input type="submit" value="Submit" /> */}

                               </form>



                               <div className="AnniversaryEditionsMonth ">

                               {/* <label>
                           
                                <select onChange={handleAnniversaryEditionsMonth}>
                                <option  selected value="Jan">Jan</option>
                                <option value="Feb">Feb</option>
                                <option  value="Mar">Mar</option>
                                <option  value="Apr">Apr</option>
                                <option  value="May">May</option>
                                <option  value="Jun">Jun</option>
                                <option  value="Jul">Jul</option>
                                <option  value="Aug">Aug</option>
                                <option  value="Sep">Sep</option>
                                <option  value="Oct">Oct</option>
                                <option  value="Nov">Nov</option>
                                <option  value="Dec">Dec</option>
                                
                                </select>
                                Month
                               
                               </label> */}

                               </div>
  
                    </div>

 

                    {filteredData?.map((item,id)=> {
                            return(

                            <div className="  col-6 col-sm-4 col-md-3 col-lg-3 edition-card" key={id}>
                                <Link to="/flip-book" className="box">
                                    <div className="date">
                                        <p>{item?.fld_month + " "}{item?.fld_year}</p>
                                        <p>{item?.fld_volume}</p>
                                    </div>
                                    <div className="image">
                                        <img src={item?.fld_image} className="img-fluid" alt={item?.fld_alt} />
                                    </div>
                                    {/* <h2>{item?.fld_long_desc}</h2> */}
                                    <p className="des">
                                    {item?.fld_short_desc}
                                    </p>
                                </Link>

                                <div className="bottom-btn">
                                    <p>Price <span>2$</span> 1.25$</p>
                                <button className="btn " onClick={()=>{
                                    handleItemToLocal(item)
                                }}>Add Edition</button>
                                </div>
                            </div>
                    )})}
                </div>
            </div>
            <Offcanvas placement="end" show={showOffcanvas} onHide={()=>{setShowOffcanvas(false)}}>
            <Offcanvas.Header closeButton>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className="">
                        {cartItems?.map((data)=>{
                            return(
                                <>
                                    <div className="row item-card">
                                        <div className="col-3">
                                            <img className="w-100" src={data?.image} alt=""/>
                                        </div>
                                        <div className="col-9 item-card-des">
                                            <div>
                                            <p>{data?.description}</p>
                                          <span>2$</span>   <span className="price"> {data?.price}$</span>
                                          </div>

                                          <div className="delete-icon">
                                          <RiDeleteBin5Fill />
                                          </div>
                                        </div>
                                        
                                       

                                    </div>
                                </>
                            )
                        })}
                        </div>
                        <span>Sub Total - USD {cartItems.length * 1.25}</span>
                        <div className="w-100">
                            <Payments usdamount={cartItems.length * 1.25}/>
                            {/* <button className="btn theme-btn w-100">Pay Now ( USD {cartItems.length * 1.25} )</button> */}
                        </div>
                    </Offcanvas.Body>
            </Offcanvas>
        </section>
    )
}