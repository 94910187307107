import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import Logo from "../assets/img/logo/DSA-transparent-logo.png";
export default function AdminNav() {
    return (
        <Navbar expand={false} className="bg-body-tertiary mb-3">
            <Container fluid>
                <Navbar.Brand href="#"><img src={Logo} className="admin-logo img-fluid" alt="" /></Navbar.Brand>
                <div className="d-flex">
                <Nav.Link href="/admin/news-list" className="me-3">News List</Nav.Link>
                <Nav.Link href="/admin/uploadfile" className="me-3">Upload File</Nav.Link>
                <Nav.Link href="/admin/articles">Articles</Nav.Link>
                </div>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand`} />
                <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand`}
                    aria-labelledby={`offcanvasNavbarLabel-expand`}
                    placement="end"
                >
                    <Offcanvas.Header closeButton>
                        <img src={Logo} className="admin-logo img-fluid" alt="" />
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3">
                        <Nav.Link href="/admin/news-list">News List</Nav.Link>
                        <Nav.Link href="/admin/uploadfile">Upload File</Nav.Link>
                        <Nav.Link href="/admin/articles">Articles</Nav.Link>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    )
}