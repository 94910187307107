import React, { useState } from 'react';
import { Upload, Button, message, Tabs, Switch, Input } from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import "./AdminPanel.css";
import axios from 'axios';
const { TabPane } = Tabs;
const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);   
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
export default function HomeMaster() {
    const [fileListMobile, setFileListMobile] = useState([]);
    const [fileListDesktop, setFileListDesktop] = useState([]);
    const [previewImageMobile, setPreviewImageMobile] = useState(null);
    const [previewImageDesktop, setPreviewImageDesktop] = useState(null);
    const [activeMobile, setActiveMobile] = useState(false);
    const [activeDesktop, setActiveDesktop] = useState(false);
    const [linkMobile, setLinkMobile] = useState('');
    const [linkDesktop, setLinkDesktop] = useState('');
    const [showLinkMobile, setShowLinkMobile] = useState(false);
    const [showLinkDesktop, setShowLinkDesktop] = useState(false);
    const [urlData, setUrlData] = useState([]);

    const handleChangeMobile = ({ fileList: newFileList }) => {
        setFileListMobile(newFileList);
        if (newFileList.length > 0) {
            const reader = new FileReader();
            reader.onload = (e) => setPreviewImageMobile(e.target.result);
            reader.readAsDataURL(newFileList[0].originFileObj);
        } else {
            setPreviewImageMobile(null);
        }
    };

    // const handleChangeDesktop = ({ fileList: newFileList }) => {
    //     setFileListDesktop(newFileList);
    //     if (newFileList.length > 0) {
    //         const reader = new FileReader();
    //         reader.onload = (e) => setPreviewImageDesktop(e.target.result);
    //         reader.readAsDataURL(newFileList[0].originFileObj);
    //     } else {
    //         setPreviewImageDesktop(null);
    //     }
    // };

    const handleRemoveMobile = () => {
        setFileListMobile([]);
        setPreviewImageMobile(null);
    };

    const handleRemoveDesktop = () => {
        setFileListDesktop([]);
        setPreviewImageDesktop(null);
    };

    const beforeUpload = (file) => {
        const isImage = file.type.startsWith('image/');
        if (!isImage) {
            message.error('You can only upload image files!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must be smaller than 2MB!');
        }
        return isImage && isLt2M;
    };
    const onaction = async (dat) => {
        const formData = new FormData();
        formData.append("filename", dat.file.name);
        formData.append("foldername", "home");
        formData.append("file", dat.file);
        await axios
          .post("https://dsalert.org/dsalert-api/AddImage", formData, {
            headers: {
              Schema: "Beads_Beauty",
            },
          })
          .then((res) => {
            setUrlData([...urlData, res.data.image]);
          })
          .catch((err) => {
            if (err.response) {
              console.log("Error Response:", err.response.data);
              console.log("Error Status:", err.response.status);
              console.log("Error Headers:", err.response.headers);
            } else if (err.request) {
              console.log("Error Request:", err.request);
            } else {
              console.log("Error Message:", err.message);
            }
            console.log("Error Config:", err.config);
        });
    };
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
        // setPreviewImage(file.url || file.preview);
        // setPreviewOpen(true);
      };
    
      const handleChangeDesktop = async ({ file, fileList }) => {
        const updatedFileList = [...fileList];
        const index = updatedFileList.findIndex((item) => item.uid === file.uid);
        if (index !== -1) {
          updatedFileList[index] = { ...file, status: "done" };
          setFileListDesktop(updatedFileList);
        }
        if (file.status === "done") {
          updatedFileList[index] = { ...file, status: "done" };
          setFileListDesktop(updatedFileList);
        } else if (file.status === "error") {
          updatedFileList[index] = { ...file, status: "error" };
          setFileListDesktop(updatedFileList);
        } else if (file.status === "removed") {
          setFileListDesktop(updatedFileList.filter((item) => item.uid !== file.uid));
        }
        if (fileList.length > 0) {
            const reader = new FileReader();
            reader.onload = (e) => setPreviewImageDesktop(e.target.result);
            reader.readAsDataURL(fileList[0].originFileObj);
        } else {
            setPreviewImageDesktop(null);
        }
      };
    return (
        <div className="banner-upload-container">
            <Tabs defaultActiveKey="1" centered>
                <TabPane tab="Mobile Banner" key="1">
                    <div className="tab-content">
                        <h2>Upload Mobile Banner</h2>
                        <div className="status-switch">
                            <span>Status: </span>
                            <Switch
                                checked={activeMobile}
                                onChange={setActiveMobile}
                                checkedChildren="Active"
                                unCheckedChildren="Inactive"
                            />
                        </div>

                        <Upload
                            listType="picture-card"
                            fileList={fileListMobile}
                            onChange={handleChangeMobile}
                            beforeUpload={beforeUpload}
                            onRemove={handleRemoveMobile}
                            maxCount={1}
                            showUploadList={false}
                        >
                            {fileListMobile.length === 0 ? (
                                <div className="upload-placeholder">
                                    <UploadOutlined /> Click to Upload Mobile Banner
                                </div>
                            ) : null}
                        </Upload>

                        {previewImageMobile && (
                            <div className="banner-preview">
                                <img src={previewImageMobile} alt="Mobile Banner Preview" className="banner-image" />
                                <Button icon={<DeleteOutlined />} onClick={handleRemoveMobile} danger>
                                    Remove Banner
                                </Button>
                            </div>
                        )}

                        <div className="link-toggle">
                            <span>Show Navigate Input: </span>
                            <Switch
                                checked={showLinkMobile}
                                onChange={setShowLinkMobile}
                                checkedChildren="Show"
                                unCheckedChildren="Hide"
                            />
                        </div>

                        {showLinkMobile && (
                            <Input
                                placeholder="Navigate to link"
                                value={linkMobile}
                                onChange={(e) => setLinkMobile(e.target.value)}
                                style={{ marginTop: '10px' }}
                            />
                        )}
                    </div>
                </TabPane>

                <TabPane tab="Desktop Banner" key="2">
                    <div className="tab-content">
                        <h2>Upload Desktop Banner</h2>
                        <div className="status-switch">
                            <span>Status: </span>
                            <Switch
                                checked={activeDesktop}
                                onChange={setActiveDesktop}
                                checkedChildren="Active"
                                unCheckedChildren="Inactive"
                            />
                        </div>

                        <Upload
                        customRequest={onaction}
                        listType="picture-card"
                        fileList={fileListDesktop}
                        onPreview={handlePreview}
                        onChange={handleChangeDesktop}
                            // listType="picture-card"
                            // fileList={fileListDesktop}
                            // onChange={handleChangeDesktop}
                            beforeUpload={beforeUpload}
                            // onRemove={handleRemoveDesktop}
                            maxCount={1}
                            showUploadList={false}
                        >
                            {fileListDesktop.length === 0 ? (
                                <div className="upload-placeholder">
                                    <UploadOutlined /> Click to Upload Desktop Banner
                                </div>
                            ) : null}
                        </Upload>

                        {previewImageDesktop && (
                            <div className="banner-preview">
                                <img src={previewImageDesktop} alt="Desktop Banner Preview" className="banner-image" />
                                <Button icon={<DeleteOutlined />} onClick={handleRemoveDesktop} danger>
                                    Remove Banner
                                </Button>
                            </div>
                        )}

                        <div className="link-toggle">
                            <span>Show Navigate Input: </span>
                            <Switch
                                checked={showLinkDesktop}
                                onChange={setShowLinkDesktop}
                                checkedChildren="Show"
                                unCheckedChildren="Hide"
                            />
                        </div>

                        {showLinkDesktop && (
                            <Input
                                placeholder="Navigate to link"
                                value={linkDesktop}
                                onChange={(e) => setLinkDesktop(e.target.value)}
                                style={{ marginTop: '10px' }}
                            />
                        )}
                    </div>
                </TabPane>
            </Tabs>
            {/* Remove and Save Buttons */}
            <div className="button-group">
                <Button className="ant-btn-dangerous">Remove Banner</Button>
                <Button type="primary" style={{ marginLeft: '10px' }}>Save</Button>
            </div>
            
        </div>

        
    )
}