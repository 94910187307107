import "./AwardsCategoryPage.css";
import { useEffect, useState } from "react";
export default function AwardsCategoryPage({ data }) {

    const [filteredData, setFilteredData] = useState(data);
    const [categoryHeading, setCategoryHeading] = useState("Peace Time")


    const getFilteredData = (category) => {
        setCategoryHeading(category)
        const filtered = data.filter(item => item.fld_category === category);
        setFilteredData(filtered);
    }

    useEffect(() => {
        const defaultData = data.filter(item => item?.fld_category === "Peace Time")
        setFilteredData(defaultData);
    }, [data]);


    return (
        <section className="section-spacing pb-0 pt-0 awards-page">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 section-spacing-y">
                        <div className="category-tab">
                            <p>
                                Categories
                            </p>
                            <ul>
                                <li className={categoryHeading === "Peace Time" ? "Active" : "" } onClick={() => getFilteredData('Peace Time')}>
                                    Peace Time
                                </li>
                                <li className={categoryHeading === "War Time" ? "Active" : "" } onClick={() => getFilteredData('War Time')}>
                                    War Time
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 mb-4">
                        <div className="main-heading mt-4">
                            <h2>{categoryHeading ? categoryHeading : "Peace Time"}</h2>
                        </div>
                    </div>
                </div>
                {filteredData?.map((item) => {
                    return (
                        <div className="row awad-page-card">
                            <div className="col-lg-5 image-section text-center">
                                <img src={item?.fld_image} className="img-fluid" alt="" />
                            </div>
                            <div className="col-lg-7 ps-lg-4">
                                <div className="ps-lg-2">
                                    <b className="fs-2">{item?.fld_heading}</b>
                                    <p className="mt-3" dangerouslySetInnerHTML={{__html :item?.fld_long_desc }}/>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </section>
    )
}