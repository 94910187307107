import "./NewsDetailSection.css";
import { useParams } from "react-router-dom";

import { useEffect, useState } from "react";
// import NavigationSection from "../../NavigationSection/NavigationSection";
// import AboutNewsletterSection from "../../AboutNewsletterSection/AboutNewsletterSection";
// import NewsletterSection from "../../../Home/NewsletterSection/NewsletterSection";
// import BrandParttern from "../../../Home/BrandParttern/BrandParttern";
// import RegionSection from "../RegionSection/RegionSection";
// import SocialIcons from "../../../SocialIcons/SocialIcons";
import PostApiCall from "../../../../Helpers/Api/PostApi";
import moment from "moment";
import { Link } from "react-router-dom";
import RelatedNews from "../RelateddNewsSection/RelatedNews";
import SocialShare from "../../../SocialShare";

export default function NewsDetailSection() {
// eslint-disable-next-line
    const { id } = useParams();

// eslint-disable-next-line
    const [newsLetterPageData, setNewsLetterPageData] = useState([])

    const getNewsLetterPageData = () => {
        PostApiCall.postRequest({whereClause:`where fld_id = '${id}' `},"GetNews").then((results) => {
            results.json().then((obj) => {
                if (results.status === 200 || results.status === 201) {
                    setNewsLetterPageData(obj.data);
                } else {
                    // notification.error({
                    //   message: `Notification error`,
                    //   description: obj.data,
                    // });
                }
            })
        })
    }

    // const newsItem = newsLetterPageData?.find(item => item.fld_id === parseInt(id));

    useEffect(() => {
        getNewsLetterPageData()
        // eslint-disable-next-line
    }, [])

    useEffect(()=>{
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },[])

    return (
        <div className="news-detail-page">
            {/* <NavigationSection />
            <AboutNewsletterSection />
            <NewsletterSection />
            <BrandParttern />
            <RegionSection /> */}
            <section className="section-spacing pt-4 news-detail-section">
                <div className="container-fluid">
                    <div className="row section-spacing-y pt-4 news-detail-section">
                        <div className="col-12 top-section ">
                            <img src={newsLetterPageData[0]?.fld_image} className="img-fluid" alt="" />
                            {newsLetterPageData[0]?.fld_image_source && <div className="source">
                            <p className=""> Source- </p>
                            <Link>
                           <p>{newsLetterPageData[0]?.fld_image_source}</p>
                            </Link>
                            </div>
}
                        </div>
                        <div className="col-lg-4">
                            <h2>
                            DSA Correspondent
                            </h2>
                            <div className="box">
                                <b>Date</b>
                                <span>
                                {moment(newsLetterPageData[0]?.fld_createdon).format('MMMM D, YYYY')}
                                </span>
                            </div>
                            <div className="box">
                                <b>
                                    Share
                                </b>
                                <span>
                                    <ul>
                                        <SocialShare shareUrl={window.location.href}/>
                                        {/* <SocialIcons/> */}
                                    </ul>
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="scroll-section"> 
                            <h3>{newsLetterPageData[0]?.fld_heading}</h3>
                            <p><b>{newsLetterPageData[0]?.fld_short_desc}</b></p>
                            <p dangerouslySetInnerHTML={{ __html: newsLetterPageData[0]?.fld_long_desc }} />
                             </div>
                        </div>
                    </div>
                </div>
            </section>

            <RelatedNews data={newsLetterPageData}/>
        </div>
    )
}