import NoImg4 from "../../../assets/img/about/Journwey so far image with text.png";
import "./JourneySection.css";
export default function JourneySection() {
    return (
        <>
            <section className="container-fluid p-0 section-spacing journey-section" id="journeysofar">
                {/* // <section> */}
                {/* <div className="container-fluid">
                <div className="row">
                    <div className="col-12 d-flex position-relative">
                        <h2 className="main-heading"> 
                Journey <span>so far</span> 
                 </h2> */}

                        <div className="bgImage">     
                            
                          </div>
       
                 <a rel="noreferrer" href="https://drive.google.com/file/d/1wYMlxdL6c3tNz5M62NvP-z6OCaAJMJID/view?usp=drive_link" target="_blank" className="image">

                <img src={NoImg4} className="img-fluid" alt="" />
                {/*  */}
                </a>
                 {/* </div>
                </div> */}
            {/* </div>  */}


            </section>
            
        </>
    )
}