import React, { useEffect, useState } from 'react';
import { Upload, Button, notification, Table } from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import "../AdminPanel.css";
import axios from 'axios';
import PostApiCall from '../../Helpers/Api/PostApi';
const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);   
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
export default function UploadPdf() {
    const randomnum = parseInt(Math.random()* 1000000)
    const [fileListDesktop, setFileListDesktop] = useState([]);
    const [previewImageDesktop, setPreviewImageDesktop] = useState(null);
    const [urlData, setUrlData] = useState([]);
    const [data,setData] = useState([]);
    const handleRemoveDesktop = () => {
        setFileListDesktop([]);
        setPreviewImageDesktop(null);
    };
    const onaction = async (dat) => {
        const formData = new FormData();
        formData.append("filename",randomnum + dat.file.name);
        formData.append("foldername", "files");
        formData.append("file", dat.file);
        await axios
          .post("https://dsalert.org/dsalert-api/AddImage", formData, {
            headers: {
              Schema: "",
            },
          })
          .then((res) => {
            setUrlData([res?.data?.Message]);
          })
          .catch((err) => {
            if (err.response) {
              console.log("Error Response:", err.response.data);
              console.log("Error Status:", err.response.status);
              console.log("Error Headers:", err.response.headers);
            } else if (err.request) {
              console.log("Error Request:", err.request);
            } else {
              console.log("Error Message:", err.message);
            }
            console.log("Error Config:", err.config);
        });
    };
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
        // setPreviewImage(file.url || file.preview);
        // setPreviewOpen(true);
      };
    
      const handleChangeDesktop = async ({ file, fileList }) => {
        const updatedFileList = [...fileList];
        const index = updatedFileList.findIndex((item) => item.uid === file.uid);
        if (index !== -1) {
          updatedFileList[index] = { ...file, status: "done" };
          setFileListDesktop(updatedFileList);
        }
        if (file.status === "done") {
          updatedFileList[index] = { ...file, status: "done" };
          setFileListDesktop(updatedFileList);
        } else if (file.status === "error") {
          updatedFileList[index] = { ...file, status: "error" };
          setFileListDesktop(updatedFileList);
        } else if (file.status === "removed") {
          setFileListDesktop(updatedFileList.filter((item) => item.uid !== file.uid));
    }
        if (fileList.length > 0) {
            const reader = new FileReader();
            reader.onload = (e) => setPreviewImageDesktop(e.target.result);
            reader.readAsDataURL(fileList[0].originFileObj);
        } else {
            setPreviewImageDesktop(null);
        }
    };
    const onsubmit = () => {
        PostApiCall.postRequest(
          {
            url: urlData,
          },
          "AddFiles"
        ).then((results) => {
          results.json().then((obj) => {
            if (results.status === 200 || results.status === 201) {
            } else {
              notification.error({
                message: `Notification error`,
                description: obj.data,
              });
            }
          });
        });
      };
      const getFiles = () => {
        PostApiCall.postRequest({whereClause:""},"GetFiles").then((results)=> {
          results.json().then((obj) => {
            if (results.status === 200 || results.status === 201) {
                setData(obj.data); 
          }else {
            notification.error({
              message: `Notification error`,
              description: obj.data,
            });
            }
          })
        })
      }
    useEffect(()=>{
        getFiles();
    },[])
    const columns = [
        {
          title: 'S No',
          dataIndex: 'sno',
        },
        {
            title: 'Url',
            dataIndex: 'url',
          },
          {
            title: '',
            dataIndex: 'btn',
          }
    ]
    const tabledata = data?.map((dat, index) => ({
        sno: index+1,
        url: dat.fld_url,
        btn: <>
        <a className='btn theme-btn' href={dat.fld_url} target='_blank'>View</a>
        </>
      }));
      
    return (
        <div className='container-fluid'>
                    <div className="banner-upload-container col-6">
                        <h2>Upload a File</h2>
                        <Upload
                        customRequest={onaction}
                        listType="picture-card"
                        fileList={fileListDesktop}
                        onPreview={handlePreview}
                        onChange={handleChangeDesktop}
                            maxCount={1}
                            showUploadList={false}
                        >
                            {fileListDesktop.length === 0 ? (
                                <div className="upload-placeholder">
                                    <UploadOutlined /> Click to Upload File
                                </div>
                            ) : null}
                        </Upload>

                        {previewImageDesktop && (
                            <div className="banner-preview">
                                <img src={previewImageDesktop} alt="Preview" className="banner-image" />
                                <Button icon={<DeleteOutlined />} onClick={handleRemoveDesktop} danger>
                                    Remove File
                                </Button>
                            </div>
                        )}
            {/* Remove and Save Buttons */}
            <div className="button-group text-end">
                <button className='btn theme-btn' onClick={onsubmit}>Save</button>
            </div>
            
        </div>
        <div className='col-12 mb-5'>
            <h2>List of Uploaded Files</h2>
        <Table
    columns={columns}
    dataSource={tabledata}
  />
  </div>
  </div>
        
    )
}