import React, { useState } from 'react';
import './AdminPanel.css';
import PostApiCall from '../Helpers/Api/PostApi';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';

export default function LoginPage() {
    let navigate = useNavigate()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Perform the login logic (like API call)
        console.log('Email:', email);
        console.log('Password:', password);
        onsubmit()
    };

    const onsubmit = () => {
        PostApiCall.postRequest(
          {
            username: email,
            password: password,
          },
          "AuthenticateUser"
        ).then((results) => {
          results.json().then((obj) => {
            if (results.status === 200 || results.status === 201) {
              sessionStorage.setItem("access", obj.token);
              window.location.href = '/admin/news-list'
            } else {
              notification.error({
                message: `Notification error`,
                description: obj.data,
              });
            }
          });
        });
      };
    return (
        <div className="login-container">
            <form className="login-form" onSubmit={handleSubmit}>
                <h2>Admin Login</h2>
                <div className="input-group">
                    <label>Email</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="input-group">
                    <label>Password</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="login-btn">
                    Login
                </button>
            </form>
        </div>
    );
}

