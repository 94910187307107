import { Link } from "react-router-dom";
import "./AboutNewsletterSection.css";
import { useContext } from "react";
import { store } from "../../../Helpers/Store/Store";
export default function AboutNewsletterSection() {
    const {setScrollToNews} = useContext(store)
    return (
        <section className="section-spacing  pt-5 pb-5 about-newsletter-section">
            <div className="container-fluid ">
                <div className="row">
                    <div className="col-md-6 left-section">
                        <div className="image">
                            <img src="https://dsalert.org/images/NewsImages/unnamed10-67801e2239256.webp" className="img-fluid" alt="" />
                        </div>
                        <div className="row">
                            {/* <div className="col-4">
                                <img src={NoImg} className="img-fluid" alt="" />
                            </div> */}
                            <div className="col-12">
                                <img src="https://dsalert.org/images/news/Germany%20Orders%20New%20Submarines.webp" className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 right-section">
                        <div className="top-section">
                            <div className="main-heading">
                                <h1>
                                    DSA <span>Newsletters</span>
                                </h1>
                            </div>
                            <b>Stay updated with us</b>
                            <p>A one-stop platform tailored to deliver world affairs encompassing defence strategic partnerships and agreements, emerging technologies and matters related to regional conflicts and security.</p>
                            <Link to="/newsletter-news" onClick={()=>setScrollToNews("regionsec")} className="theme-btn theme-btn-bg">View ALL NEWS</Link>
                        </div>
                        <div className="image">
                            <img src="https://dsalert.org/images/news/Regional%20Security%20Pact%20Focus%20for%20Albanese%20Government.webp" className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}