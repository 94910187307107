import { LuMedal } from "react-icons/lu";
import "./WelcomeSection.css";
import { FaTrophy } from "react-icons/fa";
import { useContext } from "react";
import { store } from "../../../../Helpers/Store/Store";
import { MdCastForEducation } from "react-icons/md";
export default function WelcomeSection() {
    const {defSecEduType,setDefSecEduType,defSecEduData,setDefSecEduFilteredData} = useContext(store)
    const getFilteredData = (category) => {
        if(category === ""){
            setDefSecEduFilteredData(defSecEduData);
        }else{
            const filtered = defSecEduData.filter(item => item.fld_type === category);
            setDefSecEduFilteredData(filtered);
        }
    }
    return (
        <section className="section-spacing mb-lg-5 welcome-section position-relative" id="defsecwelcome">
            {/* <img src={NoImg} className="img-fluid" alt="" /> */}
            <div className="bg"></div>
            <div className="container-fluid position-relative">
                <div className="row">
                    <div className="col-lg-8 mx-auto text-center">
                        <h2><b>Ushering an Educational Platform for Defence & Security!</b></h2>
                        <b>
                        Enhance your knowledge with our curated educational resources, designed specifically for students, career professionals, academicians and experts in the defence field. Whether you are just starting your journey or looking to deepen your expertise, our platform offers a comprehensive guide to the world of defence and security. Dive in and explore the opportunities that await you in this dynamic and rewarding field.
                        </b>
                        <p>
                            <i>
                            IT’S ALL ABOUT DEFENCE AND SECURITY
                            </i>
                        </p>
                    </div>
                    <div className="col-lg-7 mx-auto">
                        <div className="welcome-card-box">
                            <div className={defSecEduType === "Insignia" ? "welcome-card active" : "welcome-card"} onClick={()=>{setDefSecEduType("Insignia")
                                getFilteredData("Insignia")
                            }}>
                                <LuMedal />

                                <h3>Insignia</h3>
                            </div>
                            <div className={defSecEduType === "Awards" ? "welcome-card active" : "welcome-card"} onClick={()=>{setDefSecEduType("Awards")
                                getFilteredData("Awards")
                            }}>
                                <FaTrophy />
                                <h3>Awards</h3>
                            </div>
                            <div className={defSecEduType === "Careers" ? "welcome-card active" : "welcome-card"} onClick={()=>{setDefSecEduType("Careers")
                                getFilteredData("Careers")
                            }}>
                                <MdCastForEducation />
                                <h3>Careers</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}