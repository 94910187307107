import React from "react";
import { Navigate } from "react-router-dom";

function ProtectedRoutes({ element, ...rest }) {
  const isAuth = sessionStorage.getItem("access");

  // Return the element if authenticated, otherwise redirect to login page
  return isAuth ? element : <Navigate to="/admin" replace />;
}

export default ProtectedRoutes;
