// import BrandParttern from "../../Home/BrandParttern/BrandParttern";
// import NewsletterSection from "../../Home/NewsletterSection/NewsletterSection";
// import AboutNewsletterSection from "../AboutNewsletterSection/AboutNewsletterSection";
import NavigationSection from "../NavigationSection/NavigationSection";
import RegionSection from "./RegionSection/RegionSection";
import "./NewsPage.css";
import CategoriesSection from "./CategoriesSection/CategoriesSection";

import { useContext, useEffect, useState } from "react";
import PostApiCall from "../../../Helpers/Api/PostApi";
import { store } from "../../../Helpers/Store/Store";

export default function NewsPage() {
    const [newsLetterPageData, setNewsLetterPageData] = useState([])
    const [newsLetterAllData, setNewsLetterAllData] = useState([])
    const {scrollToNews,setScrollToNews, region} = useContext(store)

    const getNewsLetterPageData = () => {
        PostApiCall.postRequest({whereClause:""},"GetNews").then((results)=> {
          results.json().then((obj) => {
            if (results.status === 200 || results.status === 201) {
              setNewsLetterAllData(obj.data.filter((dat)=>dat.fld_status == "Active")); 
          }else {
            // notification.error({
            //   message: `Notification error`,
            //   description: obj.data,
            // });
            }
          })
        })
      }
useEffect(()=>{
  if(region === ""){
    setNewsLetterPageData(newsLetterAllData)
  }else{
     // eslint-disable-next-line
  setNewsLetterPageData(newsLetterAllData.filter((dat)=>dat.fld_type == region))
  }
},[region,newsLetterAllData])

    useEffect(()=>{
        getNewsLetterPageData()
    },[])

    useEffect(()=>{
      window.scrollTo({ top: 0, behavior: 'smooth' });
  },[])
  useEffect(() => {
    if (scrollToNews) {
      const targetDiv = document.getElementById(scrollToNews);
      if (targetDiv) {
        const rect = targetDiv.getBoundingClientRect();
        const targetScrollTop = rect.top + window.pageYOffset;

        const scrollStep = (targetScrollTop - window.pageYOffset) / 20; // Divide the scroll into 20 steps
        let currentScrollTop = window.pageYOffset;

        const smoothScroll = () => {
          if (
            (scrollStep > 0 && currentScrollTop < targetScrollTop) ||
            (scrollStep < 0 && currentScrollTop > targetScrollTop)
          ) {
            currentScrollTop += scrollStep;
            window.scrollTo({
              top: currentScrollTop,
              behavior: "auto", // Disable smooth scrolling for this operation
            });
            requestAnimationFrame(smoothScroll);
          } else {
            window.scrollTo({
              top: targetScrollTop,
              behavior: "smooth", // Re-enable smooth scrolling for final step
            });
            setScrollToNews(null);
          }
        };

        smoothScroll();
      }
    }
    // eslint-disable-next-line
  }, [scrollToNews]);

    return (
        <div className="newsletter-news-page">
            <NavigationSection />
            {/* <AboutNewsletterSection /> */}
            {/* <NewsletterSection /> */}
            {/* <BrandParttern /> */}
            <RegionSection />
            <CategoriesSection data = {newsLetterPageData}/>
        </div>
    )
}