import React, { useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { store } from '../../Helpers/Store/Store';
import PostApiCall from '../../Helpers/Api/PostApi';
import { notification } from 'antd';
import "./LoginModal.css"
import leftImg from "../../assets/img/left-banner.png"
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Logo from "../../assets/img/logo/dsa-white-logo.png";
import SocialIcons from '../SocialIcons/SocialIcons';
import {IoLogoYoutube } from "react-icons/io";
import { FcGoogle } from "react-icons/fc";
 


export default function LoginModal() {
    const [email, setEmail] = useState('');
    const [name,setName] = useState("")
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const { show,setShow, modalType,setModalType } = useContext(store)

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     console.log('Email:', email);
    //     console.log('Password:', password);
    //io

    const handleClose = () => setShow(false);
    const onlogin = (event,type) => {
        event.preventDefault()
        if (email !== "") {
          if (password !== "") {
            if (type == 'login'){
              onsubmit();
            }else{
              onregister()
            }
          } else {
            notification.error({
              message: `Notification error`,
              description: "Please Enter Password",
            });
          }
        } else {
          notification.error({
            message: `Notification error`,
            description: "Please Enter Name",
          });
        }
      };
    const onsubmit = () => {
        PostApiCall.postRequest(
          {
            email: email,
            password: password,
          },
          "login"
        ).then((results) => {
          results.json().then((obj) => {
            if (results.status === 200 || results.status === 201) {
              sessionStorage.setItem("access", obj.token);
            } else {
              notification.error({
                message: `Notification error`,
                description: obj.data,
              });
            }
          });
        });
      };
      const onregister = () => {
        PostApiCall.postRequest(
          {
            email: email,
            password: password,
            name:name,
            action: name ? "Register" : "login",
          },
          "register"
        ).then((results) => {
          results.json().then((obj) => {
            if (results.status === 200 || results.status === 201) {
              sessionStorage.setItem("access", obj.token);
            } else {
              notification.error({
                message: `Notification error`,
                description: obj.data,
              });
            }
          });
        });
      };
    return (
        <Modal show={show} onHide={handleClose} className='login-modal' >
          <Modal.Header closeButton>
          <Modal.Title className='modal-title'>
          <img src={Logo}/>
         
            {/* <select className='language-dropdown'>
              <option value=''>EN</option>
              <option value=''>HN</option>
              <option value=''>MAT</option>
              <option value=''>GUJ</option>
            </select> */}

        </Modal.Title>
          
          </Modal.Header>
            <Modal.Body className='login-body grid-example'>
            <Container>
            <Row>
            <Col md={6}  >
              <div className='leftImg'>
                <img src={leftImg} className='img-fluid' alt=''/>
              </div>
              </Col>


              <Col md={6}  >
                {modalType === "Login" ?
            <form className="login-form" onSubmit={(e)=>onlogin(e,'login')}>
                <h2>Login</h2>
                <p>Welcome to <i>DSA</i></p>
                <div className="input-group">
                    {/* <label>Email</label> */}
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        placeholder='Email'
                        
                    />
                </div>
                <div className="input-group">
                    {/* <label>Password</label> */}
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        placeholder='Password'
                    />
                </div>
                <h6>forget password ?</h6>
                <div className='content'>
                  <p className='or'>or</p>

                </div>

                 
                <button className='login-with-google'>
                  Login with Google  
                  <FcGoogle/>

                </button>
                <button type="submit" className="login-btn">
                    Login
                </button>
            <p className='mt-2'>Don't have an account ? <span onClick={()=>{setModalType("Register")}}>Register</span></p>
            <div className='social-icons'>
                <SocialIcons/>
                </div>
            </form>
            :
            <form className="login-form" onSubmit={(e)=>onlogin(e,'register')}>
                <h2>Register</h2>
                <p>Welcome to <i>DSA</i></p>
                <div className="input-group">
                    {/* <label>Email</label> */}
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        placeholder='Email'
                    />
                </div>
                <div className="input-group">
                    {/* <label>Full Name</label> */}
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        placeholder='Name'
                    />
                </div>
                <div className="input-group">
                    {/* <label>Password</label> */}
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        placeholder='Password'
                    />
                </div>
                <div className="input-group">
                    {/* <label>Re - Enter Password</label> */}
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        placeholder=' Re-Enter Password'
                    />
                </div>
                <button type="submit" className="login-btn">
                    Register
                </button>
                <p className='mt-2'>Already have an account ? <span onClick={()=>{setModalType("Login")}}>Login</span></p>
             <div className='social-icons'>
                <SocialIcons/>
                </div>
            </form>
            }
    </Col>
 </Row>
</Container>
            </Modal.Body>
        </Modal>
    );
}

