import { Link } from "react-router-dom";
import "./NewArticlesSection.css";
export default function NewArticlesSection({ data }) {
    return (
        <section className=" section-spacing new-articles-section">
            <div className="container-fluid">
                <div className="row">

                <div className="left-section order-2">

                    <div className=" col-10 top-section mt-1">
                      
                        <h2 className="main-heading">
                            {data[0]?.fld_heading}
                        </h2>
                    </div>
                    <div className="  col-10 bottom-section  mt-1">
                        <p>
                            {data[0]?.fld_short_desc}
                          {data[0]?.fld_status === "NEW" &&  <Link to={`/article/${data[0]?.fld_id}`}><span className="read-more-btn ms-2"> read more</span></Link>}
                        </p>
                        <div className="d-flex flex-wrap gap-4 gap-lg-5 text-capitalize mt-3 mb-5">


                            <b>
                                {/* written by - {data[0]?.fld_published_by} */}
                            </b>
                            <b>
                                {/* Date - {moment(data[0]?.fld_createdon).format('MMMM D, YYYY')} */}
                            </b>
                            {/* <b>Time - {moment(data[0]?.fld_createdon).format('HH:mm:ss')}</b> */}
                        </div>
                    </div>

                    </div>
                    <div className="col-12 order-1">
                        <Link to={`/article/${data[0]?.fld_id}`}>
                            <img src={data[0]?.fld_image} className="img-fluid" alt={data[0]?.fld_alt} />
                        </Link>
                        {data[0]?.fld_image_src &&<div className="source">
                        {/* <p className=""> Source- </p> */}
                        {/* <Link> */}
                        <p>{data[0]?.fld_image_src}</p>
                        {/* </Link> */}


                        </div>
                        }
                     
                         
                    </div>
                </div>
            </div>
        </section>
    )
}